import React from 'react';
import _ from 'lodash';
import { Link } from 'gatsby';

function PostTags({ tags }) {
  return (
    <ul className='flex flex-row mt-4'>
      {tags &&
        tags.map((tag, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index} className='border-2 bg-blue-200 rounded-xl px-2 py-0.5 font-normal text-xs mr-1'>
            <Link key={tag} style={{ textDecoration: 'none' }} to={`/tags/${_.kebabCase(tag)}`}>
              {tag}
            </Link>
          </li>
        ))}
    </ul>
  );
}

export default PostTags;
